import React from "react";

function PrivacyPolicy() {
  return (
    <div>
      <div className="container">
        <h4>Privacy Policy</h4>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, amet
          iure ipsum odit possimus unde quae odio beatae ut. Quos soluta rem sed
          nemo voluptatem odit tempora nihil officia, totam, consequatur modi
          aut perspiciatis, consectetur magni accusantium accusamus minus?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, amet
          iure ipsum odit possimus unde quae odio beatae ut. Quos soluta rem sed
          nemo voluptatem odit tempora nihil officia, totam, consequatur modi
          aut perspiciatis, consectetur magni accusantium accusamus minus?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, amet
          iure ipsum odit possimus unde quae odio beatae ut. Quos soluta rem sed
          nemo voluptatem odit tempora nihil officia, totam, consequatur modi
          aut perspiciatis, consectetur magni accusantium accusamus minus?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, amet
          iure ipsum odit possimus unde quae odio beatae ut. Quos soluta rem sed
          nemo voluptatem odit tempora nihil officia, totam, consequatur modi
          aut perspiciatis, consectetur magni accusantium accusamus minus?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, amet
          iure ipsum odit possimus unde quae odio beatae ut. Quos soluta rem sed
          nemo voluptatem odit tempora nihil officia, totam, consequatur modi
          aut perspiciatis, consectetur magni accusantium accusamus minus?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, amet
          iure ipsum odit possimus unde quae odio beatae ut. Quos soluta rem sed
          nemo voluptatem odit tempora nihil officia, totam, consequatur modi
          aut perspiciatis, consectetur magni accusantium accusamus minus?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, amet
          iure ipsum odit possimus unde quae odio beatae ut. Quos soluta rem sed
          nemo voluptatem odit tempora nihil officia, totam, consequatur modi
          aut perspiciatis, consectetur magni accusantium accusamus minus?
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit, amet
          iure ipsum odit possimus unde quae odio beatae ut. Quos soluta rem sed
          nemo voluptatem odit tempora nihil officia, totam, consequatur modi
          aut perspiciatis, consectetur magni accusantium accusamus minus?
        </p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
