//array of car objects
export const cars = [
  {
    id: 4,
    name: "Nissan Juke",
    make: "2014 Hyundai Xcent S 1.2 ",
    model: "Civic",
    kms: "12000",
    owner: "first owner",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/16528691896284c845b2275_1.jpeg",
      "https://www.rao-international.com/vehicle_image/16528691906284c8462187b_13.jpeg",
      "https://www.rao-international.com/vehicle_image/16528691906284c8464d961_17.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 5,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/165692492562c2aafd68ccf_6.jpeg",
      "https://www.rao-international.com/vehicle_image/165692492562c2aafd60475_5.jpeg",
      "https://www.rao-international.com/vehicle_image/165692492562c2aafd68ccf_6.jpeg",
      "https://www.rao-international.com/vehicle_image/165692492562c2aafd79b1d_8.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 6,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 7,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 8,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 9,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 100,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015811623ab9c308a15_6.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015811623ab9c312509_7.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015811623ab9c32fd01_10.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 10,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 1,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 11,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 12,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 13,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
  {
    id: 14,
    make: "Honda",
    model: "Civic",
    year: 2000,
    color: "red",
    price: 20000,
    images: [
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
      "https://www.rao-international.com/vehicle_image/1648015810623ab9c2c9db2_1.jpeg",
    ],
    description: "This is a description of the car",
    service: "This is a service of the car",
    city: "This is a city of the car",
    address: "This is a address of the car",
    neighbour: "This is a neighbour of the car",
  },
];
